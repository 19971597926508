<template>
  <section class="section">
    <b-loading :active="isLoading"></b-loading>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div
          class="tile is-child card is-flex is-flex-direction-column has-background-eurotext-light"
        >
          <div class="card-header has-background-primary">
            <p
              class="card-header-title is-justify-content-center has-text-light"
            >
              {{ $t("cut_PDF") }}
            </p>
          </div>

          <div class="card-content">
            <b-field type="is-danger">
              <b-upload
                v-model="splitFile"
                drag-drop
                expanded
                accept=".pdf"
                class="is-white"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>{{ $t("upload.form.file-button-label") }}</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <b-field grouped group-multiline v-show="splitFile.name">
              <div class="control">
                <b-tag
                  close-type="is-primary"
                  attached
                  closable
                  aria-close-label="Close tag"
                  @close="splitFile = {}"
                >
                  {{ splitFile.name }}
                </b-tag>
              </div>
            </b-field>

            <b-field>
              <template #label>
                <span class="has-text-light">{{ $t("page_cut") }}</span>
              </template>
              <b-numberinput
                v-model="pageNum"
                min="2"
                controls-alignment="right"
                controls-position="compact"
              ></b-numberinput>

              <!-- <b-input v-model="pageNum"></b-input> -->
            </b-field>
          </div>

          <footer
            class="card-footer has-background-white"
            style="margin-top: auto"
          >
            <b-button
              @click="split"
              native-type="button"
              type="is-primary"
              class="card-footer-item"
              :disabled="!splitFile.name"
            >
              {{ $t("buttons.cut") }}
            </b-button>
          </footer>
        </div>
      </div>

      <div class="tile is-parent">
        <div
          class="tile is-child card is-flex is-flex-direction-column has-background-eurotext-light"
        >
          <div class="card-header has-background-primary">
            <p
              class="card-header-title is-justify-content-center has-text-light"
            >
              {{ $t("join_PDF") }}
            </p>
          </div>
          <div class="card-content">
            <div>
              <b-field>
                <b-upload
                  v-model="mergeFiles"
                  multiple
                  drag-drop
                  expanded
                  accept=".pdf"
                  class="is-white"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>{{ $t("upload.form.file-button-label") }}</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <b-field grouped group-multiline v-show="mergeFiles.length > 0">
                <div
                  class="control"
                  v-for="(file, index) in mergeFiles"
                  :key="index"
                >
                  <b-tag
                    close-type="is-primary"
                    attached
                    closable
                    aria-close-label="Close tag"
                    @close="deleteDropFile(index)"
                  >
                    {{ file.name }}
                  </b-tag>
                </div>
              </b-field>
              <!-- <span
                  v-for="(file, index) in mergeFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span> -->
            </div>

            <!-- <div>
              <b-button @click="merge" native-type="button" type="is-primary">
                Unisci
              </b-button>
            </div> -->
          </div>
          <footer
            class="card-footer has-background-white"
            style="margin-top: auto"
          >
            <b-button
              @click="merge"
              native-type="button"
              type="is-primary"
              class="card-footer-item"
              :disabled="mergeFiles.length === 0"
            >
              {{ $t("buttons.join") }}
            </b-button>
          </footer>
        </div>
      </div>
    </div>

    <!-- <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title">Taglia PDF</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <section>
          <b-field>
            <b-upload v-model="splitFile" drag-drop expanded accept=".pdf">
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>{{ $t("upload.form.file-button-label") }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags" v-show="splitFile.name">
            <span class="tag is-primary">
              {{ splitFile.name }}
              <button
                class="delete is-small"
                type="button"
                @click="splitFile = {}"
              ></button>
            </span>
          </div>
        </section>

        <section class="section">
          <b-field label="Numero pagina">
            <b-input v-model="pageNum"></b-input>
          </b-field>
          <b-button @click="split" native-type="button" type="is-primary">
            Dividi
          </b-button>
        </section>
      </div>
    </b-collapse>

    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title">Unisci PDF</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <section>
          <b-field>
            <b-upload
              v-model="mergeFiles"
              multiple
              drag-drop
              expanded
              accept=".pdf"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>{{ $t("upload.form.file-button-label") }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in mergeFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </section>

        <section class="section">
          <b-button @click="merge" native-type="button" type="is-primary">
            Unisci
          </b-button>
        </section>
      </div>
    </b-collapse> -->
  </section>
</template>

<script>
import { commonMixin } from "@/mixins";
import { getBase64FromFile, hexSHA256FromFile } from "../../helpers";
import { inputService, policiesService } from "../../services";
import FileSaver from "file-saver";
export default {
  mixins: [commonMixin],
  data() {
    return {
      merging: false,
      splitting: false,
      mergeFiles: [],
      splitFile: {},
      fileLocations: [],
      pageNum: 2,
    };
  },
  computed: {
    isLoading() {
      return this.merging || this.splitting;
    },
  },
  methods: {
    deleteDropFile(index) {
      this.mergeFiles.splice(index, 1);
    },
    async upload(files) {
      try {
        for (let i = 0; i < files.length; i++) {
          console.log("uploading", i);
          const file = files[i];
          const b64 = await getBase64FromFile(file);
          const sha256 = await hexSHA256FromFile(file);
          const mime = file.type;
          const fileRes = await inputService.uploadFile(
            this.companyName,
            b64,
            mime,
            sha256
          );
          const serverFileLocation = fileRes["original_path"];
          this.fileLocations.push(serverFileLocation);
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          message: this.$t("error.generic"),
          type: "is-danger",
        });
        return false;
      }
    },
    async merge() {
      try {
        if (this.mergeFiles.length < 2) {
          this.$buefy.dialog.alert({
            message: this.$t("not_enough_docs", { min: 2 }),
            type: "is-danger",
            hasIcon: true,
            ariaModal: true,
          });
          return;
        }
        this.merging = true;
        const success = await this.upload([...this.mergeFiles]);
        if (success) {
          const res = await policiesService.mergePDFs(
            this.companyName,
            [...this.fileLocations],
            this.mergeFiles[0].name
          );
          const fileName = Object.keys(res)[0];
          console.log("fileName", fileName);
          const fData = Uint8Array.from(
            atob(res[fileName])
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const file = new File([fData], `${fileName}`, {
            type: "application/pdf",
          });
          FileSaver.saveAs(file);
        }
        this.mergeFiles = [];
        this.fileLocations = [];
      } catch (e) {
        console.error(e);
      } finally {
        this.merging = false;
      }
    },
    async split() {
      try {
        this.splitting = true;
        const success = await this.upload([this.splitFile]);
        if (success) {
          const res = await policiesService.splitPDF(
            this.companyName,
            this.fileLocations[0],
            this.pageNum,
            this.splitFile.name
          );
          for (const key of Object.keys(res)) {
            const fData = Uint8Array.from(
              atob(res[key])
                .split("")
                .map((char) => char.charCodeAt(0))
            );
            const file = new File([fData], `${key}`, {
              type: "application/pdf",
            });
            FileSaver.saveAs(file);
          }
        }
        this.splitFile = {};
        this.fileLocations = [];
      } catch (error) {
        console.error(error);
      } finally {
        this.splitting = false;
      }

      // this.upload([this.splitFile]).then((success) => {
      //   if (success) {
      //     policiesService
      //       .splitPDF(this.companyName, this.fileLocations[0], this.pageNum)
      //       .then((res) => {
      //         for (const key of Object.keys(res)) {
      //           const fData = Uint8Array.from(
      //             atob(res[key])
      //               .split("")
      //               .map((char) => char.charCodeAt(0))
      //           );
      //           const file = new File([fData], `${key}`, {
      //             type: "application/pdf",
      //           });
      //           FileSaver.saveAs(file);
      //         }
      //       })
      //       .finally(() => {
      //         this.splitFile = {};
      //         this.fileLocations = [];
      //         this.splitting = false;
      //       });
      //   } else {
      //     this.splitting = false;
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.is-white {
  background-color: white;
  border-radius: $radius + 2px;
}
</style>
